import EmailIcon from "@mui/icons-material/Email";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { FormEvent, useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import TransitionsModal from "../components/TransitionsModal";
import FooterHomeOne from "./FooterHomeOne";
import HomeOneHeader from "./HomeOneHeader";
import "./forgot-password.css";

const ForgotPassword = () => {
  const [email, setEmail] = React.useState<string>("");
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  useEffect(() => {
    window.location.href = "https://urry.com/reset-password";
  });

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    setLoading(true);
    window.scrollTo(0, 0);
    axios
      .get(`https://order-api.urry.com/identity/v1.0/ForgotPassword?email=${email}`)
      .then(() => {
        setLoading(false);
        setShowModal(true);
        setEmail('');
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <div>
      <HomeOneHeader />
      {loading ? (
        <section className="text-lg text-center py-6 appie-hero-area loading">
        <ScaleLoader
          color="#34D399"
          loading={loading}
          height={25}
          width={3}
          radius={3}
          margin={4}
        />
      </section>
      ) : (
      <section className="appie-hero-area forgot-password-container">
        <div className="container">
          <div className="align-items-center">
            <div className="common-inner-blckdiv">
              <div className="common-inner-banner" style={{ paddingTop: "0" }}>
                <h2 style={{ zIndex: "10" }}>パスワードの再発行</h2>
              </div>
            </div>
            <div className="contact_wrap">
              <div className="contactus_wrapper">
                <div className="container">
                  <div className="row contact_form">
                    <form onSubmit={onSubmit}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          marginBottom: "15px",
                        }}
                      >
                        <EmailIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          required
                          fullWidth
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          id="input-with-sx"
                          label="メールアドレス"
                          variant="standard"
                        />
                      </Box>
                      <FormControl
                        fullWidth
                        className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow"
                      >
                        <Button variant="contained" type="submit">
                        送信する
                        </Button>
                      </FormControl>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      )}
      <FooterHomeOne className="features-home-one" />
      {showModal && (
        <TransitionsModal
          title="メールが配信されました"
          content="パスワード変更の方法についてメールをお送りいたしましたので、受信箱をご確認ください。"
          closeUrl=""
        />
      )}
    </div>
  );
};
export default ForgotPassword;

import { Link } from 'react-router-dom';
import yellowEllipseBig from '../../assets/images/yellow-ellipse-big.png';
import yellowEllipse from '../../assets/images/yellow-ellipse.png';
import './banner-two.css';

export default function UrryBannerTwo() {
    return (
        <section className="urry-banner-two w-100 d-flex">
            <img className="small-ellipse" alt="" src={yellowEllipse} />

            <img className="big-ellipse" alt="" src={yellowEllipseBig} />

            <div className="block-one">
                <div className="block-one-inner">
                    <p className="title01">長岡屋<a href="https://urry.com/" className="back-link" target="blank">+URRY</a></p>

                    <p className="title01">特別キャンペーン</p>

                    <p className="content01 font-weight-normal">
                        URRYで1,500円（税別）以上のお買い上げをいただいたお客様の中から厳正なる抽選で商品券が当たります。
                    </p>

                    <p className="title02">キャンペーンに参加する方法</p>

                    <p className="content02">１）本ページにてお客様の情報を登録。</p>

                    <p className="content02">
                        ２）メールで送られてくるログイン情報でアプリにログインする
                    </p>

                    <p className="content02 font-weight-normal">
                        ３）URRYを通じて長岡屋の商品を注文する
                    </p>

                    <Link className="urry-btn mt-35" to="/urry-form">
                        キャンペーンに参加する
                    </Link>
                </div>
            </div>

            <div className="block-two mt-5">
                <p>1等 商品券 30,000円分（1名様）</p>

                <p>2等 商品券 10,000円分（3名様）</p>

                <p>3等 商品券 5,000円分（5名様）</p>

                <p>4等 檸檬堂定番レモン 1ケース（5名様）</p>
            </div>
        </section>
    );
}

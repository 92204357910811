import { useEffect } from 'react';
import './terms-conditions.css';

const TermsConditions = () => {
	useEffect(() => {
		window.location.href = "https://urry.com/terms-conditions";
	});
	return (
		<>
		</>
	);
}
export default TermsConditions;

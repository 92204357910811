import BackToTop from '../BackToTop';
import TaniguchiForm from '../TaniguchiForm';
import FaqHomeOne from './FaqHomeOne';
import FeaturesHomeOne from './FeaturesHomeOne';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import ProjectHomeOne from './ProjectHomeOne';
import ServicesHomeOne from './ServicesHomeOne';
import UrryBannerOne from './UrryBannerOne';

function TaniguchiPage() {
    window.location.href = "https://urry.com";
    const signup = new URLSearchParams(window.location.search).get('signup');

    if(signup === "1"){
        return (
            <>
                <TaniguchiForm/>
            </>
        );
    }
    return (
        <div className="taniguchi">
            {/* <Drawer drawer={drawer} action={drawerAction.toggle} /> */}
            <HomeOneHeader />
            <HeroHomeOne />
            <UrryBannerOne />
            <ServicesHomeOne />
            <FeaturesHomeOne className="features-home-one" />
            {/* <UrryBannerTwo /> */}
            <FaqHomeOne />
            <ProjectHomeOne />
            <FooterHomeOne />
            <BackToTop />
        </div>
    );
}

export default TaniguchiPage;

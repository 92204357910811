/* eslint-disable */
import Modal from "react-modal/lib/components/Modal";
import closeIconBlue from "../../assets/images/urry/close-icon-blue.svg";
import "./urry-form.css";

const TCModal = ({ isOpen, setIsOpen }) => {
  return (
    <Modal
    isOpen={isOpen}
    id="form-modal"
    style={{ overlay: { background: 'rgba(18,26,30,0.3)' } }}
>
    <img
        src={closeIconBlue}
        alt=""
        className="close-icon-blue"
        onClick={() => setIsOpen(false)}
    />

    <h1>キャンペーン規約</h1>

    <p>株式会社長岡屋商店（以下「当社」といいます）が実施する「URRYオーダーキャンペーン」（以下「本キャンペーン」といいます）のご参加にあたっては、本キャンペーン規約（以下「本規約」といいます）をご確認いただき､同意いただいた上でご参加ください。</p>
    <p>&nbsp;</p>
    <h2>1. 本キャンペーンについて<br/><br/>キャンペーン内容</h2>

    <p>以下の対象期間中にURRY Orderを使用して1,500円（税抜）以上、ご注文いただいたお客様を対象に、抽選で以下の特典をプレゼントいたします。</p>
    <p>&nbsp;</p>
    <h3>特典</h3>

    <p>1等 JCBギフトカード 30,000円分（1名様）</p>

    <p>2等 JCBギフトカード 10,000円分（3名様）</p>

    <p>3等 JCBギフトカード 5,000円分（5名様）</p>

    <p>4等 商品券（無料引換券） 檸檬堂定番レモン350ml 24本入り1ケース分（5名様）</p>

    <ul>
        <li>当選の発表は、URRYアプリに事前登録いただいた連絡先を使用し、電子メールにて当選のご連絡を行います。</li>
    </ul>
    
    <p>&nbsp;</p>

    <h3>対象期間</h3>

    <p>お客様のURRYアプリ利用開始日から2023年1月31日（火）まで（以下｢対象期間｣といいます)となります。</p>

    <p>&nbsp;</p>

    <h3>抽選対象者</h3>

    <p>URRYアプリに事前登録いただき、対象期間中に、URRY Orderを利用して1,500円（税抜）以上ご注文いただいたお客様</p>

    <ul>
        <li>対象期間中の累計利用金額を算出し、対象期間終了後に抽選します。ただし、キャンセルされた発注（対象期間終了後のキャンセル含む）については、利用実績から除外いたします。</li>
        <li>URRYアプリは株式会社伴内酒店（以下「伴内酒店」といいます）が運営いたします。お客様がURRYアプリの事前登録手続き後、伴内酒店より案内される手続きを行っていただいた後、利用可能となります。</li>
        <li>URRY Orderでのご注文には、お客様にて別途URRYアプリへの事前登録が必要となります。</li>
    </ul>
    <p>&nbsp;</p>
    <h2>2. その他の注意事項</h2>

    <ul>
        <li>当社は、本キャンペーンの適切な運営を妨げる事象が生じた場合、これに類する状況が生じた場合、またはその他本キャンペーンを継続し難い事由が生じた場合、いつでも本キャンペーンを中止または延期することができるものといたします。</li>
        <li>本キャンペーンの内容は､予告なく変更する場合がございます｡また､本規約は､当社の判断により随時変更することができるものとします｡この場合､当社の本キャンペーンサイトなどに掲載又はURRYアプリにご登録いただいたメールアドレスにメール送信することにより､変更内容を随時告知するものとします｡当該告知以降、当該変更内容が、すべての参加者と当社との間で適用されるものといたしますのであらかじめご了承ください。</li>
        <li>当社は、参加者が本キャンペーンに参加したこと、または当選したことに起因する損害・不利益について一切の責任を負わないものといたします。</li>
    </ul>
    <p>&nbsp;</p>
    <h2>3. お客様の情報の取り扱い</h2>
    <ul>
      <li>
        当社は、URRYに関連して取得するお客様の情報と別に、本キャンペーンの運営に関連してお客様から取得した個人情報については､特典付与手続きにおける利用､本キャンペーンに関連したご案内のための利用､お客様からのお問い合わせ対応のための利用など、本キャンペーンの運営に関する目的のために利用いたします。
      </li>
      <li>
        当社は､お客様の個人情報の流出･漏洩の防止､その他個人情報の安全管理のために必要かつ適切な措置を講じるものとし､法令などに基づく正当な理由がある場合を除き､お客様の同意なく目的外での利用および本キャンペーンの実施に関する業務委託先以外の第三者への提供はいたしません
      </li>
    </ul>
</Modal>
);
};

export default TCModal;

import urryLogo from "../../assets/images/urry/urry-logo.png";
import "./notification-banner.css";

export default function NotificationBanner() {
  return (
    <section className="w-100 noti-container">
      <div className="noti-logo">
        <a href="javascipt:void(0)"><img alt="" src={urryLogo} /></a>
        <div className="noti-title">
        ISEYA の受発注システム URRYの導入に<br/>
          ご協力いただきありがとうございます
        </div>
        <div className="noti-description">
        <p>
            <span className="red-text">
              大変恐れ入れますが、2023年6月19日をもってURRYの登録方法が変わりました。
            </span>
            <br />
            <br />
            後日新たなチラシがお客様の元に届きますので、そこに記載されている案内に従ってURRYの本登録を行なってください。
            <br />
            <br />
            ご迷惑をおかけして大変申し訳ございません。
            <br />
            引き続きどうぞ宜しくお願いいたします。
            <br />
            <br />
            URRYチームより
            <br />
            <br />
            <br />
          </p>

        </div>
      </div>
    </section>
  );
}

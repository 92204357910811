import andIcon from '../../assets/images/icon/and_blue.svg';
import brandLogo from '../../assets/images/taniguchi/taniguchi-genos-logo.png';
import urryLogo2 from '../../assets/images/urry/logo (1).svg';
import './banner-one.css';

export default function UrryBannerOne() {
    return (
        <section className="taniguchi-banner-one d-flex w-100">
            <div className="block-one">
                <div className="img-container d-flex align-items-center m-auto">
                    <img alt="" src={urryLogo2} />

                    <img alt="" src={andIcon} />

                    <img alt="" src={brandLogo} style={{height:"auto"}}/>
                </div>
            </div>

            <div className="block-two font-weight-normal">
                <p>谷口の販促が変わります</p>

                <p>平素は大変お世話になっております。</p>

                <p>この度、飲食店様にお得なキャンペーンやセール情報をいち早くお届けできるように「URRY」を導入することになりました。</p>

                <p>URRYはスマホのアプリ、Webからの発注に対応しています。</p>
                <p>是非ともご登録のほどよろしくお願いいたします。<br></br>
                株式会社 谷口
                </p>
            </div>
        </section>
    );
}

/* eslint-disable */
import { Link } from 'react-router-dom';
import urryThumbOne from '../../assets/images/taniguchi/thumb1.svg';
import urryThumbTwo from '../../assets/images/product-card.png';

function HeroHomeOne({ className }) {
    return (
        <>
            <section className={`appie-hero-area ${className || ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content">
                                <h1 className="appie-title">
                                    飲食店のみなさんへ<br/>
                                    スマホでいつでもどこでも簡単に注文が可能になりました！
                                </h1>
                                <Link to="/taniguchi-form">
                                    <a className="main-btn" href="#">
                                        今すぐ登録する
                                    </a>
                                </Link>
                                <ul>
                                    <li>
                                        <a href="https://apps.apple.com/jp/app/urry/id1660720664" target={"_blank"}><i className="fab fa-apple"></i> Download for iOS</a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="https://play.google.com/store/apps/details?id=ccbji.co.jp.urry" target={"_blank"}><i className="fab fa-google-play"></i> Download for Android</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img src={urryThumbOne} alt="" width="310px"/>
                                </div>
                                <div
                                    className="thumb-2 wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                    <img src={urryThumbTwo} alt="" width="150px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroHomeOne;

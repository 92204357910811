import { useEffect, useState } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import BannaiPage from './components/BannaiPage';
import ContactUs from './components/ContactUs/index.tsx';
import ForgotPassword from './components/ForgotPassword/index.tsx';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import MiyoshiyaPage from "./components/Miyoshiya";
import PrivacyPolicy from './components/PrivacyPolicy';
import ResetPassword from './components/ResetPassword/index.tsx';
import TaniguchiForm from './components/TaniguchiForm';
import TermsConditions from './components/TermsConditions';
import UrryForm from './components/UrryForm';
import UrryLanding from './components/UrryLandingPage';
import IkejimaPage from './components/ikejima';
import IseyaPage from "./components/iseya";
import KawachiyaGenosPage from './components/kawachiya-genos';
import TaniguchiPage from './components/taniguchi';

function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/urry-form" component={UrryForm} />
                            {/* <Route exact path="/bannai-form" component={BannaiForm} />
                            <Route exact path="/bannai-form" component={BannaiForm} /> */}
                            {/* <Route exact path="/kawachiya-genos-form" component={KawachiyaGenosForm} /> */}
                            <Redirect from="/kawachiya-genos-form" to="/kawachiya-genos" />
                            {/* <Route exact path="/iseya-form" component={IseyaForm} /> */}
                            <Redirect from="/iseya-form" to="/iseya" />
                            <Route exact path="/" component={UrryLanding} />
                            <Route exact path="/bannai" component={BannaiPage} />
                            <Redirect from="/bannai-form" to="/bannai" />
                            <Route exact path="/kawachiya-genos" component={KawachiyaGenosPage} />
                            <Route exact path="/iseya" component={IseyaPage} />
                            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                            <Route exact path="/terms-conditions" component={TermsConditions} />
                            <Route exact path="/contact-us" component={ContactUs} />
                            <Route exact path="/forgot-password" component={ForgotPassword} />
                            <Route exact path="/redeem-password" component={ForgotPassword} />
                            <Route exact path="/reset-password" component={ResetPassword} />
                            <Route exact path="/miyoshiya" component={MiyoshiyaPage} />
                            {/* <Route exact path="/miyoshiya-form" component={MiyoshiyaForm} /> */}
                            <Redirect from="/miyoshiya-form" to="/miyoshiya" />
                            <Route exact path="/taniguchi" component={TaniguchiPage} />
                            <Route exact path="/taniguchi-form" component={TaniguchiForm} />
                            <Route exact path="/ikejima" component={IkejimaPage} />
                            <Redirect from="/ikejima-form" to="/ikejima" />
                            {/* <Route exact path="/ikejima-form" component={IkejimaForm} /> */}
                            {/* <Route exact path="/home-six" component={HomeSix} />
                            <Route exact path="/home-seven" component={HomeSeven} />
                            <Route exact path="/home-eight" component={HomeEight} />
                            <Route exact path="/home-dark" component={HomeDark} />
                            <Route exact path="/home-rtl" component={HomeRtl} />
                            <Route exact path="/news" component={News} />
                            <Route exact path="/news/single-news" component={SingleNews} />
                            <Route exact path="/service" component={Service} />
                            <Route exact path="/about-us" component={AboutUs} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/error" component={Error} />
                            <Route exact path="/about-us-another" component={AboutUsTwo} />
                            <Route exact path="/shops" component={Shops} />
                            <Route exact path="/shops/shop-details" component={ShopDetails} />
                            <Route component={Error} /> */}
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;

import IconOne from '../../assets/images/urry/Icons_Urry-01.png';
import IconTwo from '../../assets/images/urry/Icons_Urry-02.png';
import IconThree from '../../assets/images/urry/Icons_Urry-03.png';
import IconFour from '../../assets/images/urry/Icons_Urry-04.png';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title"><a href="https://urry.com/" className="back-link" target="blank">URRYアプリのメリット</a></h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center item-2 mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={IconTwo} alt="" />
                            </div>
                            <h4 className="appie-title">素早く注文する</h4>
                            <p>
                                商品検索から発注まで
                                今まで時間がかかった作業があっという間に終わります。
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={IconOne} alt="" />
                            </div>
                            <h4 className="appie-title">商品検索が簡単</h4>
                            <p>
                                酒屋の取り扱いの全商品からすぐに目当てのものが見つかります。
                                飲食店が取扱いの無い、新たな商品の出会いが待っています。
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={IconThree} alt="" />
                            </div>
                            <h4 className="appie-title">直感的なデザイン</h4>
                            <p>
                                新しいテクノロジーに対して不安に思う方でもすぐにUrryでお探しの飲料品を見つけ、発注できます。トレーニングやマニュアルは一切いりません。
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img src={IconFour} alt="" />
                            </div>
                            <h4 className="appie-title">丁寧なサポート</h4>
                            <p>
                                システムに関して質問がありましたらアプリで簡単にカスタマーサポートに回答・支援してもらえます。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;

import { useEffect } from 'react';
import './privacy-policy.css';

const PrivacyPolicy = () =>{
	useEffect(() => {
        window.location = "https://urry.com/privacy-policy";
    });
	return (
		<>
		</>
	);
}

export default PrivacyPolicy;

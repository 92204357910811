import { FormHelperText } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { FormEvent, useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import urryThumbTwo from "../../assets/images/urry/cooc-japan.b0e2058c.jpg";
import HomeOneHeader from "../ForgotPassword/HomeOneHeader";
import FooterHomeOne from "../UrryLandingPage/FooterHomeOne";
import TransitionsModal from "../components/TransitionsModal";
import "./contact-us.css";

const ContactUs = () => {
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const [sex, setSex] = React.useState<string>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [inquiryFrom, setInquiryFrom] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	useEffect(() => {
		window.location.href = "https://urry.com/contact";
	});

  const handleChangeInquiryFrom = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setInquiryFrom(value);
  };
  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmit(true);
    setShowModal(false);
    if (!sex || !firstName || !inquiryFrom || !phone || !email || !message) {
      return;
    }
    if(!expression.test(email)){
      return;
    }
    setLoading(true);
    window.scrollTo(0, 0);
    const payload = {
      sex: sex,
      firstName: firstName,
      inquiryFrom: inquiryFrom,
      phone: phone,
      email: email,
      message: message,
    };
    axios
      .post(
        `https://order-api.urry.com/customers/inquiry/v1.0/SendInquiry`,
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(() => {
        setLoading(false);
        setInquiryFrom('');
        setSex('');
        setFirstName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setIsSubmit(false);
        setShowModal(true);
      })
      .catch((error: any) => {
        setLoading(false);
        setIsSubmit(false);
        console.log(error);
      });
    
  };
  
  return (
    <div>
      <HomeOneHeader />
      {loading ? (
        <section className="text-lg text-center py-6 appie-hero-area loading">
        <ScaleLoader
          color="#34D399"
          loading={loading}
          height={25}
          width={3}
          radius={3}
          margin={4}
        />
      </section>
      ) : (
        <section className="appie-hero-area">
          <div className="container">
            <div className="align-items-center">
              <div className="common-inner-blckdiv">
                <div className="common-inner-banner" style={{ paddingTop: "0" }}>
                  <h2 style={{ zIndex: "10" }}>お問い合わせ</h2>
                </div>
              </div>
              <div className="contact_wrap">
                <div className="contactus_wrapper">
                  <div className="container">
                    <div className="contact-us-lhs">
                      <div className="row contact_form">
                        <form onSubmit={onSubmit} autoComplete="off" noValidate>
                          <TextField
                            onChange={(e) => setSex(e.target.value)}
                            className="sex-input"
                            style={{ width: "50%", borderRadius: "10px" }}
                            id="sex"
                            label="性"
                            variant="outlined"
                            aria-describedby="helper-text-sex"
                            required
                            helperText={
                              isSubmit && sex.length === 0
                                ? "この項目は必須です"
                                : ""
                            }
                            FormHelperTextProps={{
                              classes: {
                                root: "error",
                              },
                            }}
                            value={sex}
                          />
                          <TextField
                            onChange={(e) => setFirstName(e.target.value)}
                            className="firstname-input"
                            style={{ width: "50%", borderRadius: "10px" }}
                            id="first-name"
                            label="名"
                            variant="outlined"
                            required
                            helperText={
                              isSubmit && firstName.length === 0
                                ? "この項目は必須です"
                                : ""
                            }
                            FormHelperTextProps={{
                              classes: {
                                root: "error",
                              },
                            }}
                            value={firstName}
                          />
                          <FormControl fullWidth margin="dense">
                            <InputLabel id="inquiryFrom">
                              お問い合わせ元*
                            </InputLabel>
                            <Select
                              value={inquiryFrom}
                              placeholder="選択"
                              labelId="inquiryFrom"
                              id="inquiryFrom"
                              label="Inquiry From"
                              onChange={handleChangeInquiryFrom}
                              required
                            >
                              <MenuItem value="酒屋様">酒屋様</MenuItem>
                              <MenuItem value="飲食店様">飲食店様</MenuItem>
                            </Select>
                            {isSubmit && inquiryFrom.length === 0 && (
                              <FormHelperText className="error">
                                この項目は必須です
                              </FormHelperText>
                            )}
                          </FormControl>
                          <FormControl fullWidth margin="dense">
                            <TextField
                              id="outlined-basic"
                              label="電話番号"
                              type="tel"
                              onChange={(e) => setPhone(e.target.value)}
                              required
                              value={phone}
                            />
                            {isSubmit && phone.length === 0 && (
                              <FormHelperText className="error">
                                この項目は必須です
                              </FormHelperText>
                            )}
                          </FormControl>
                          <FormControl fullWidth margin="dense">
                            <TextField
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                              id="outlined-basic"
                              label="メールアドレス"
                              required
                              value={email}
                            />
                            {isSubmit && email.length === 0 && (
                              <FormHelperText className="error">
                                この項目は必須です
                              </FormHelperText>
                            )}
                            {isSubmit && email.length > 0 && !expression.test(email) && (
                              <FormHelperText className="error">
                                無効な電子メール
                              </FormHelperText>
                            )}
                          </FormControl>
                          <FormControl fullWidth margin="dense">
                            <TextField
                              onChange={(e) => setMessage(e.target.value)}
                              id="outlined-basic"
                              label="メッセージ"
                              multiline
                              rows={4}
                              required
                              value={message}
                            />
                            {isSubmit && message.length === 0 && (
                              <FormHelperText className="error">
                                この項目は必須です
                              </FormHelperText>
                            )}
                          </FormControl>
                          <FormControl
                            fullWidth
                            className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow"
                          >
                            <Button variant="contained" type="submit">
                              送信
                            </Button>
                          </FormControl>
                        </form>
                      </div>
                    </div>
                    <div className="contact-us-rhs">
                      <img srcSet={urryThumbTwo} src={urryThumbTwo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      
      <FooterHomeOne className="features-home-one" />
      {showModal && (
        <TransitionsModal
          title="お問い合わせフォームが送信されました。"
          content="内容を確認し、できる限りお早めに返信させていただきます。"
          closeUrl=""
        />
      )}
    </div>
  );
};
export default ContactUs;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import function1 from '../../assets/images/urry/function-1.svg';
import function2 from '../../assets/images/urry/function-2.svg';
import function3 from '../../assets/images/urry/function-3.svg';
import iconArrow from '../../assets/images/urry/icon-arrow.png';
import iconCatalogue from '../../assets/images/urry/icon-catalogue.png';
import iconMagnifierGlass from '../../assets/images/urry/icon-magnifier-glass.png';
import './features-home-one.css';

function FeaturesHomeOne({ className }) {
    const [tab, setTab] = useState('setting');
    const handleClick = (e, value) => {
        e.preventDefault();
        setTab(value);
    };
    return (
        <section className={`appie-features-area pt-100 ${className}`} id="features">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-3">
                        <div className="appie-features-tabs-btn">
                            <div
                                className="nav d-flex flex-row flex-md-column nav-pills"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <a
                                    onClick={(e) => handleClick(e, 'setting')}
                                    className={`nav-link ${tab === 'setting' ? 'active' : ''}`}
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    href="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    {/* <i className="fas fa-cog" /> */}
                                    <img
                                        src={iconCatalogue}
                                        className="d-none d-md-inline"
                                        alt=""
                                    />

                                    <span className="d-none d-md-inline-block">
                                        長岡屋の全商品カタログ
                                    </span>

                                    <span className="d-block d-md-none">
                                        全商品
                                        <br />
                                        カタログ
                                    </span>
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'report')}
                                    className={`nav-link ${tab === 'report' ? 'active' : ''}`}
                                    id="v-pills-profile-tab"
                                    data-toggle="pill"
                                    href="#v-pills-profile"
                                    role="tab"
                                    aria-controls="v-pills-profile"
                                    aria-selected="false"
                                >
                                    {/* <i className="fas fa-exclamation-triangle" /> */}
                                    <img
                                        src={iconMagnifierGlass}
                                        className="d-none d-md-inline"
                                        alt=""
                                    />

                                    <span className="d-none d-md-inline-block">簡単検索機能</span>

                                    <span className="d-block d-md-none">
                                        簡単
                                        <br />
                                        検索機能
                                    </span>
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'notice')}
                                    className={`nav-link ${tab === 'notice' ? 'active' : ''}`}
                                    id="v-pills-messages-tab"
                                    data-toggle="pill"
                                    href="#v-pills-messages"
                                    role="tab"
                                    aria-controls="v-pills-messages"
                                    aria-selected="false"
                                >
                                    {/* <i className="fas fa-bell" /> */}
                                    <img src={iconArrow} className="d-none d-md-inline" alt="" />

                                    <span className="d-none d-md-inline-block">
                                        ワンクリックの再発注
                                    </span>

                                    <span className="d-block d-md-none">
                                        ワンクリックの
                                        <br />
                                        再発注
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className={`${
                                    tab === 'setting' ? 'show active' : ''
                                } tab-pane fade`}
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-thumb text-center wow animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img src={function1} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-content wow animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">
                                                スピーディな発注を可能とする機能
                                            </h3>
                                            <p>
                                                商品検索から再発注まで、仕事の効率を大幅に向上してみませんか？
                                            </p>
                                            <Link className="main-btn" to="/urry-form">
                                                今すぐ登録する
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'report' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-profile"
                                role="tabpanel"
                                aria-labelledby="v-pills-profile-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-thumb text-center animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img src={function2} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">
                                                スピーディな発注を可能とする機能
                                            </h3>
                                            <p>
                                                商品検索から再発注まで、仕事の効率を大幅に向上してみませんか？
                                            </p>
                                            <Link className="main-btn" to="urry-form">
                                                今すぐ登録する
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${tab === 'notice' ? 'show active' : ''} tab-pane fade`}
                                id="v-pills-messages"
                                role="tabpanel"
                                aria-labelledby="v-pills-messages-tab"
                            >
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-thumb text-center animated fadeInUp"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <img src={function3} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div
                                            className="appie-features-content animated fadeInRight"
                                            data-wow-duration="2000ms"
                                            data-wow-delay="600ms"
                                        >
                                            <h3 className="title">
                                                スピーディな発注を可能とする機能
                                            </h3>
                                            <p>
                                                商品検索から再発注まで、仕事の効率を大幅に向上してみませんか？
                                            </p>
                                            <Link className="main-btn" to="/urry-form">
                                                今すぐ登録する
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeaturesHomeOne;

import React from 'react';
import BackToTop from '../BackToTop';
import UrryForm from '../UrryForm';
import FaqHomeOne from './FaqHomeOne';
import FeaturesHomeOne from './FeaturesHomeOne';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import ProjectHomeOne from './ProjectHomeOne';
import ServicesHomeOne from './ServicesHomeOne';
import UrryBannerOne from './UrryBannerOne';
import UrryBannerTwo from './UrryBannerTwo';

function UrryLanding() {
    const signup = new URLSearchParams(window.location.search).get('signup');

    if(signup === "1"){
        return (
            <>
                <UrryForm/>
            </>
        );
    }
    return (
        <>
            {/* <Drawer drawer={drawer} action={drawerAction.toggle} /> */}
            <HomeOneHeader />
            <HeroHomeOne />
            <UrryBannerOne />
            <ServicesHomeOne />
            <FeaturesHomeOne className="features-home-one" />
            <UrryBannerTwo />
            <FaqHomeOne />
            <ProjectHomeOne />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default UrryLanding;

import backgroundDesktop from "../../assets/images/urry/background_desktop.png";
import BackToTop from '../BackToTop';
import FooterHomeSecond from './FooterHomeSecond';
import NotificationBanner from './NotificationBanner';

function BannaiPage() {
    // const signup = new URLSearchParams(window.location.search).get('signup');

    // if(signup === "1"){
    //     return (
    //         <>
    //             <BannaiForm/>
    //         </>
    //     );
    // }
    return (
        <div className="miyoshiya" style={{backgroundImage: `url${backgroundDesktop}`}}>
            {/* <Drawer drawer={drawer} action={drawerAction.toggle} /> */}
            {/* <HomeOneHeader />
            <HeroHomeOne />
            <UrryBannerOne />
            <ServicesHomeOne />
            <FeaturesHomeOne className="features-home-one" />
            <UrryBannerTwo />
            <FaqHomeOne /> */}
            <NotificationBanner />
            <FooterHomeSecond />
            <BackToTop />
        </div>
    );
}

export default BannaiPage;

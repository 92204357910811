/* eslint-disable */
import { useState } from 'react';
import urryLogo from '../../assets/images/urry/logo (1).svg';
import TCModal from '../UrryForm/TCModal';
import './footer-home-one.css';

function FooterHomeOne({ className }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <TCModal isOpen={isOpen} setIsOpen={setIsOpen}/>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo footer-logo">
                                    <img src={urryLogo} alt="" />
                                </div>
                                <p>
                                    <a href="https://urry.com/" target="blank">URRY</a>とは、
                                    <a href="https://en.ccbji.co.jp/" target="_blank">コカ・コーラボトラーズジャパングループ</a>
                                    により開発され、飲食店が酒屋にスマホでいつでもこでも飲料品を簡単に注文出来る発注アプリです。
                                </p>
                                {/* <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Company</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/Service">Service</Link>
                                    </li>
                                    <li>
                                        <a href="#">Case Studies</a>
                                    </li>
                                    <li>
                                        <Link to="/news">Blog</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                {/*<h4 className="title">お役立ちリンク</h4>
                                <ul>
                                    {/* <li>
                                        <Link to="/urry-form" style={{ padding: '0', margin: '0' }}>
                                            事前登録フォーム
                                        </Link>
                                    </li> 
                                    <li>
                                       <Link to="/privacy-policy" style={{ padding: '0', margin: '0' }}>
                                            プライバシーポリシー
                                        </Link>
                                    </li>
                                    <li>
                                       <Link to="/terms-conditions" style={{ padding: '0', margin: '0' }}>
                                            利用規約
                                        </Link>
                                    </li>
                                    <li>
                                        <span
                                            className="trigger-modal"
                                            onClick={() => setIsOpen(true)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            キャンペーン規約
                                        </span>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">お問合せ</h4>
                                <ul>
                                    <li><a href="https://urry.com/contact" target="blank">お問い合わせフォーム</a></li>
                                    <li>受付時間：平日 10時～18時</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="https://apps.apple.com/jp/app/urry/id1660720664" target={"_blank"}>
                                                <i className="fab fa-apple" /> Download for iOS
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="https://play.google.com/store/apps/details?id=ccbji.co.jp.urry" target={"_blank"}>
                                                <i className="fab fa-google-play" /> Download for
                                                Android
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="copyright-text">
                                    <p>Copyright © 2021 Appie. All rights reserved.</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;

/* eslint-disable */
import urryLogo from "../../assets/images/urry-logo.png";
import appStoreIco from "../../assets/images/urry/appStore.png";
import ggPlayIcon from "../../assets/images/urry/google_play.png";
import "./footer-home-second.css";

function FooterHomeSecond({ className }) {

  return (
    <>
      <section className={`second-footer${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="footer-about-widget">
                <div className="logo footer-logo mobile-logo">
                  <a href="#">
                    <img src={urryLogo} alt="" />
                  </a>
                </div>
                <p className="title-footer">
                  URRYとは、コカ・コーラボトラーズジャパングループにより開発され、飲食店が酒屋にスマホでいつでも、どこでも飲料品を簡単に注文出来る発注アプリです。
                </p>
              </div>
              <div className="footer-about-widget app-download">
              <a
                    href="https://apps.apple.com/jp/app/urry/id1660720664"
                    target={"_blank"}
                    className="app-download-1 button-link"
                  >
                   <img src={appStoreIco}/>
                   <span className="text-download">
                        Download on the<br/>
                        <span className="ios-name">App Store</span>
                   </span>
                  </a>
                <a
                    href="https://play.google.com/store/apps/details?id=ccbji.co.jp.urry"
                    target={"_blank"}
                    className="button-link"
                  >
                   <img src={ggPlayIcon}/>
                   <span className="text-download">
                        GET IN ON<br/>
                        <span className="ios-name">Google Play</span>
                   </span>
                  </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-6"></div>
            <div className="col-lg-4 col-md-12 contact-container">
              <div className="footer-navigation">
                <h4 className="title">CONTACT</h4>
                <a href="https://urry.com/contact" target={"_blank"} className="contact-us">
                問い合わせする
                </a>
                <p className="business-hour">受付時間：平日 9時～18時</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-copyright footer-copyright-2 d-flex align-items-center justify-content-between pt-35">
                <div className="copyright-text">
                  <p>URRY Copyright © 2023 All rights reserved</p>
                </div>
                <div className="copyright-text term">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="privacy-policy"><a href="/privacy-policy" target="_blank" style={{color:"white"}}>プライバシーポリシー</a></p>
                    <p><a href="/terms-conditions" target="_blank" style={{color:"white"}}>規約と条件</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeSecond;

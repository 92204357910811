import React from 'react';
import andIcon from '../../assets/images/icon/and.svg';
import nagaokayaLogo from '../../assets/images/nagaokaya-logo.png';
import urryLogo from '../../assets/images/urry-logo.png';
import './banner-one.css';

export default function UrryBannerOne() {
    return (
        <section className="urry-banner-one d-flex w-100">
            <div className="block-one">
                <div className="img-container d-flex align-items-center m-auto">
                    <img alt="" src={urryLogo} />

                    <img alt="" src={andIcon} />

                    <img alt="" src={nagaokayaLogo} />
                </div>
            </div>

            <div className="block-two font-weight-normal">
                <p>長岡屋商店からの重要なお知らせ</p>

                <p>長岡屋商店の​発注方法が変わります​</p>

                <p>平素は大変お世話になっております。</p>

                <p>
                    この度、飲食店様によりスムーズにご発注頂く仕組みを導入致します。より簡単に発注ができ、キャンペーンやセール情報をいち早くお届けできるようになります。あわせて弊社の全ての取り扱い商品を掲載しており、より飲食店様に寄り添った内容になっております。
                </p>

                <p>是非ともご登録のほどよろしくお願いいたします。</p>

                <p className="text-right">株式会社長岡屋商店　代表　大井國平</p>
            </div>
        </section>
    );
}

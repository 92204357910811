/* eslint-disable */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import closeIconBlue from '../../assets/images/urry/close-icon-blue.svg';
import urryLogoBlue from '../../assets/images/urry/logo (1).svg';
import TCModal from '../UrryForm/TCModal';
import './urry-form.css';

function UrryForm({ showClose = true }) {
    const [isOpen, setIsOpen] = useState(false);
    const [currentMonth, setCurrentMonth] = useState((new Date()).getMonth() + 1);
    function onClick(e){
        e.preventDefault();
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/mc-validate.js";
        script.async = true;
        document.body.appendChild(script);
        const script2 = document.createElement('script');
        script2.src = "/validate.js";
        document.body.appendChild(script2);
        var newDate = new Date();
        newDate.setDate(newDate.getDate()+ 15);
        setCurrentMonth(newDate.getMonth() + 1);
    }, []);

    return (
        <>
            <TCModal isOpen={isOpen} setIsOpen={setIsOpen}/>

            <div className="m-auto urry-form">
                <img src={urryLogoBlue} className="urry-logo-blue" alt="" />

                {!isOpen && showClose && (
                    <Link to="/">
                        <img src={closeIconBlue} alt="" className="close-icon-blue" />
                    </Link>
                )}

                <div id="mc_embed_signup">
                    <form onSubmit={onClick} action="https://urry.us10.list-manage.com/subscribe/post?u=0cb7d1f1ff1f64e7d5c353923&amp;id=3790e42c2c&amp;f_id=0050c8e5f0"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                    >
                        <div id="mc_embed_signup_scroll">
                            <h2 className="text-center">ユーザー登録</h2>

                            <div className="indicates-required text-center title01">
                                <span className="asterisk font-weight-normal">
                                    以下のフォームにお客様の情報を登録してください。登録完了後メールで
                                    <br />
                                    URRYアカウントのログイン詳細をメールでお伝えいたします。
                                </span>
                            </div>

                            <div className="indicates-required text-center privacy title02">
                                <span className="asterisk font-weight-normal">
                                    URRYは「
                                    <a target="_blank" rel="noreferrer" href="https://order.urry.com/privacy-policy">プライバシーポリシー</a>
                                    」にしたがってお客様の情報を取り扱います。また、お客様のご連絡先に各サービスや特集などのご案内をお送りすることがございます。
                                </span>
                                <p>&nbsp;</p>
                                <p>※が必須入力項目です。</p>
                            </div>

                            <div className="d-flex flex-row w-100 justify-content-between input-container input-container01">
                                <div className="d-flex flex-column mc-field-group">
                                    <label htmlFor="mce-LNAME">
                                        氏<span className="asterisk">*</span>
                                    </label>

                                    <input
                                        type="text"
                                        // value=""
                                        name="LNAME"
                                        className="required"
                                        id="mce-LNAME"
                                        required=""
                                    />

                                    <span id="mce-LNAME-HELPERTEXT" className="helper_text"></span>
                                </div>

                                <div className="d-flex flex-column mc-field-group">
                                    <label htmlFor="mce-FNAME">
                                        名<span className="asterisk">*</span>
                                    </label>

                                    <input
                                        type="text"
                                        // value=""
                                        name="FNAME"
                                        className="required"
                                        id="mce-FNAME"
                                        required=""
                                    />

                                    <span id="mce-FNAME-HELPERTEXT" className="helper_text"></span>
                                </div>
                            </div>

                            <div className="input-container">
                                <div className="mc-field-group w-100">
                                    <label htmlFor="mce-EMAIL">
                                        メールアドレス
                                        <span className="asterisk">*</span>
                                    </label>

                                    <input
                                        type="email"
                                        // value=""
                                        name="EMAIL"
                                        className="required email"
                                        id="mce-EMAIL"
                                        required=""
                                    />

                                    <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                                </div>
                            </div>

                            <div className="d-flex flex-row justify-content-between input-container input-container03">
                            <div className="d-flex flex-column mc-field-group size1of2">
                                    <label htmlFor="mce-MMERGE4">
                                        電話番号<span className="asterisk">*</span>
                                    </label>

                                    <input
                                        type="text"
                                        name="MMERGE4"
                                        className="required"
                                        // value=""
                                        id="mce-MMERGE4"
                                        required=""
                                    />

                                    <span
                                        id="mce-MMERGE4-HELPERTEXT"
                                        className="helper_text"
                                    ></span>
                                </div>

                                <div className="d-flex flex-column mc-field-group size1of2">
                                    <label htmlFor="mce-MMERGE9">
                                        Fax番号
                                    </label>

                                    <input
                                        type="text"
                                        name="MMERGE9"
                                        className=""
                                        // value=""
                                        id="mce-MMERGE9"
                                    />

                                    <span
                                        id="mce-MMERGE9-HELPERTEXT"
                                        className="helper_text"
                                    ></span>
                                </div>
                            </div>

                            {/* <div className="mc-field-group size1of2 input-container">
                                <label htmlFor="mce-BIRTHDAY-month">
                                    誕生日
                                    <span className="asterisk">*</span>
                                </label>

                                <div className="datefield">
                                    <span className="subfield monthfield">
                                        <input
                                            className="birthday required"
                                            type="text"
                                            pattern="[0-9]*"
                                            // value=""
                                            size="2"
                                            maxLength="2"
                                            name="BIRTHDAY[month]"
                                            id="mce-BIRTHDAY-month"
                                            required=""
                                        />
                                    </span>{' '}
                                    /{' '}
                                    <span className="subfield dayfield">
                                        <input
                                            className="birthday required"
                                            type="text"
                                            pattern="[0-9]*"
                                            // value=""
                                            size="2"
                                            maxLength="2"
                                            name="BIRTHDAY[day]"
                                            id="mce-BIRTHDAY-day"
                                            required=""
                                        />
                                    </span>
                                    <span className="small-meta nowrap">(月/日)</span>
                                </div>

                                <span id="mce-BIRTHDAY-HELPERTEXT" className="helper_text"></span>
                            </div> */}

                            <div className="input-container">
                                <div className="d-flex flex-column mc-field-group w-100">
                                    <label htmlFor="mce-MMERGE11">企業名 
                                    </label>

                                    <input
                                        type="text"
                                        // value=""
                                        name="MMERGE11"
                                        className=""
                                        id="mce-MMERGE11"
                                    />

                                    <span
                                        id="mce-MMERGE11-HELPERTEXT"
                                        className="helper_text"
                                    ></span>
                                </div>
                            </div>

                            <div className="input-container">
                                <div className="d-flex flex-column w-100 mc-field-group">
                                    <label htmlFor="mce-MMERGE19">
                                        店舗名
                                        <span className="asterisk">*</span>
                                    </label>

                                    <input
                                        type="text"
                                        // value=""
                                        name="MMERGE19"
                                        className="required"
                                        id="mce-MMERGE19"
                                        required=""
                                    />

                                    <span
                                        id="mce-MMERGE19-HELPERTEXT"
                                        className="helper_text"
                                    ></span>
                                </div>
                            </div>

                            <div className="input-container">
                                <div className="d-flex flex-column w-100 mc-field-group">
                                    <label htmlFor="mce-MMERGE10">店舗のカテゴリー </label>

                                    <select name="MMERGE10" className="" id="mce-MMERGE10">
                                        <option value=""></option>
                                        <option value="居酒屋">居酒屋</option>
                                        <option value="カフェ">カフェ</option>
                                        <option value="食堂">食堂</option>
                                        <option value="ダイニング">ダイニング</option>
                                        <option value="中華">中華</option>
                                        <option value="バー">バー</option>
                                        <option value="焼肉">焼肉</option>
                                        <option value="レストラン">レストラン</option>
                                        <option value="和食">和食</option>
                                        <option value="その他">その他</option>
                                    </select>

                                    <span
                                        id="mce-MMERGE10-HELPERTEXT"
                                        className="helper_text"
                                    ></span>
                                </div>
                            </div>

                            <div className="input-container">
                                <div className="mc-field-group size1of2">
                                    <label htmlFor="mce-MMERGE20-month">店舗開店日 </label>

                                    <div className="datefield birthday-field">
                                        <span className="subfield monthfield">
                                            <input
                                                className="datepart "
                                                type="text"
                                                pattern="[0-9]*"
                                                // value=""
                                                placeholder="MM"
                                                size="2"
                                                maxLength="2"
                                                name="MMERGE20[month]"
                                                id="mce-MMERGE20-month"
                                            />
                                        </span>{' '}
                                        /{' '}
                                        <span className="subfield dayfield">
                                            <input
                                                className="datepart "
                                                type="text"
                                                pattern="[0-9]*"
                                                // value=""
                                                placeholder="DD"
                                                size="2"
                                                maxLength="2"
                                                name="MMERGE20[day]"
                                                id="mce-MMERGE20-day"
                                            />
                                        </span>{' '}
                                        /{' '}
                                        <span className="subfield yearfield">
                                            <input
                                                className="datepart "
                                                type="text"
                                                pattern="[0-9]*"
                                                // value=""
                                                placeholder="YYYY"
                                                size="4"
                                                maxLength="4"
                                                name="MMERGE20[year]"
                                                id="mce-MMERGE20-year"
                                            />
                                        </span>
                                        <span className="small-meta nowrap">(月/日/年)</span>
                                    </div>

                                    <span
                                        id="mce-MMERGE20-HELPERTEXT"
                                        className="helper_text"
                                    ></span>
                                </div>
                            </div>

                            <div className="mc-field-group input-group">
                                <strong>
                                    <span className="trigger-modal" onClick={() => setIsOpen(true)}>
                                        キャンペーン規約
                                    </span>
                                    に同意{' '}
                                </strong>
                                <ul>
                                    <li>
                                        <input
                                            type="radio"
                                            value="はい、URRYに事前登録してキャンペーンに応募します"
                                            name="MMERGE21"
                                            id="mce-MMERGE21-0"
                                            className="required"
                                            required=""
                                        />
                                        <label htmlFor="mce-MMERGE21-0">
                                            はい、URRYに事前登録してキャンペーンに応募します
                                        </label>
                                    </li>
                                </ul>
                                <span id="mce-MMERGE21-HELPERTEXT" className="helper_text"></span>
                            </div>

                            <div id="mce-responses" className="clear foot">
                                <div
                                    className="response"
                                    id="mce-error-response"
                                    style={{ display: 'none' }}
                                ></div>

                                <div
                                    className="response"
                                    id="mce-success-response"
                                    style={{ display: 'none' }}
                                ></div>
                            </div>

                            <div
                                style={{
                                    position: 'absolute',
                                    left: '-5000px',
                                }}
                                aria-hidden="true"
                            >
                                <input
                                    type="text"
                                    name="b_0cb7d1f1ff1f64e7d5c353923_3790e42c2c"
                                    tabIndex="-1"
                                    // value=""
                                />
                            </div>

                            <div className="optionalParent ">
                                {/* <div className="clear foot"> */}
                                <input
                                    type="submit"
                                    value="登録する"
                                    name="subscribe"
                                    id="mc-embedded-subscribe"
                                    className="button-container mt-45"
                                />

                                {/* <p className="brandingLogo">
                                    <a
                                        href="http://eepurl.com/ia4YoT"
                                        title="Mailchimp - email marketing made easy and fun"
                                    >
                                        <img
                                            src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"
                                            alt=""
                                        />
                                    </a>
                                </p> */}
                                {/* </div> */}
                            </div>
                        </div>
                        <div id="thank-you" style={{display:'none'}}>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <h2>ご登録ありがとうございます！</h2>
                            <p>&nbsp;</p>
                            <p>URRYアプリのリリース後、{currentMonth}月中に事前登録の電話番号やメールアドレスに通知します。​</p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default UrryForm;

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FilledInput,
  FormHelperText,
  IconButton,
  InputAdornment
} from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import React, { FormEvent } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import HomeOneHeader from "../ForgotPassword/HomeOneHeader";
import FooterHomeOne from "../UrryLandingPage/FooterHomeOne";
import TransitionsModal from "../components/TransitionsModal";
import "./reset-password.css";

const ResetPassword = () => {
  const [password, setPassword] = React.useState<string>("");
  const [invalidPassword, setInvalidPassword] = React.useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [invalidConfirmPassword, setInvalidConfirmPassword] =
    React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [resetPassword, setResetPassword] = React.useState<boolean>(false);
  const [resetPasswordError, setResetPasswordError] =
    React.useState<string>("");
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const passwordRegex = new RegExp(
    "[^ ]{8,16}"
  );
  const onSubmit = async (e: FormEvent) => {
    const query = new URLSearchParams(window.location.search);
    e.preventDefault();
    setIsSubmit(true);
    if (!passwordRegex.test(password)) {
      setInvalidPassword(true);
      return;
    }
    setInvalidPassword(false);
    if (confirmPassword !== password) {
      setInvalidConfirmPassword(true);
      return;
    }
    setInvalidConfirmPassword(false);
    setLoading(true);
    window.scrollTo(0, 0);
    const payload = {
      Token: query.get("q"),
      NewPassword: password,
    };
    axios
      .post(`https://order-api.urry.com/identity/v1.0/ResetPassword`, payload, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setLoading(false);
        setIsSubmit(false);
        setShowModal(true);
        setResetPassword(true);
        if (!response.data.success) {
          setResetPassword(false);
          setResetPasswordError(response.data.errorMessage);
        }else{
          setPassword('');
          setConfirmPassword('');
        }
        console.log(response);
      })
      .catch((error: any) => {
        setLoading(false);
        setIsSubmit(false);
        setResetPassword(false);
        setResetPasswordError(error);
        console.log(error);
      });
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <div>
      <HomeOneHeader />
      {loading ? (
        <section className="text-lg text-center py-6 appie-hero-area loading">
          <ScaleLoader
            color="#34D399"
            loading={loading}
            height={25}
            width={3}
            radius={3}
            margin={4}
          />
        </section>
      ) : (
        <section className="appie-hero-area forgot-password-container">
          <div className="container">
            <div className="align-items-center">
              <div className="common-inner-blckdiv">
                <div
                  className="common-inner-banner"
                  style={{ paddingTop: "0" }}
                >
                  <h2 style={{ zIndex: "10" }}>パスワードの変更</h2>
                </div>
              </div>
              <div className="contact_wrap">
                <div className="contactus_wrapper">
                  <div className="container">
                    <div className="row contact_form">
                      <form onSubmit={onSubmit} autoComplete="off">
                        <FormControl fullWidth>
                          <FilledInput
                            placeholder="パスワード"
                            style={{ backgroundColor: "white" }}
                            required
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            id="input-password-sx"
                          />
                          {isSubmit && invalidPassword && (
                            <FormHelperText className="error">
                              パスワードは8文字以上のものを設定してください。
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl fullWidth>
                          <FilledInput
                            style={{ backgroundColor: "white" }}
                            required
                            fullWidth
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            id="input-confirm-password-sx"
                            placeholder="パスワードの確認"
                          />
                          {isSubmit && invalidConfirmPassword && (
                            <FormHelperText className="error">
                              パスワードが一致しません。両方のパスワード欄に同じパスワードを入力してください。
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl
                          fullWidth
                          className="flex-grow-0 md:flex-grow lg:flex-grow xl:flex-grow"
                        >
                          <Button variant="contained" type="submit" style={{marginTop: "20px"}}>
                          パスワードを変更する
                          </Button>
                        </FormControl>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <FooterHomeOne className="features-home-one" />
      {showModal && (
        <TransitionsModal
          title={resetPassword ? "パスワード変更完了" : "Failed"}
          content={
            resetPassword
              ? "パスワードが変更されました。"
              : resetPasswordError
          }
          closeUrl=""
        />
      )}
    </div>
  );
};
export default ResetPassword;

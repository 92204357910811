import { Link } from 'react-router-dom';
import contactThumb from '../../assets/images/taniguchi/cta-image.png';

function ProjectHomeOne({ className }) {
    return (
        <>
            <section className={`appie-project-area pb-100 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-project-box wow animated slideInUp"
                                data-wow-duration="1000ms"
                                data-wow-delay="0ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="appie-project-content">
                                            <h3 className="title">
                                                飲料品発注を
                                                <br />
                                                URRYにお任せください！
                                            </h3>
                                            <p>今すぐ登録してみてください！</p>
                                            <form action="#">
                                                <div className="input-box mt-30">
                                                    <Link className="yellow-btn" to="/taniguchi-form">
                                                        今すぐ登録する
                                                    </Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="appie-project-thumb">
                                    <img src={contactThumb} width="542px" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProjectHomeOne;
